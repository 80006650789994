var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "li",
    {
      class: {
        "list-item": true,
        disableHover: _vm.disableHover,
        filterMode: _vm.filterMode,
        grayColor: _vm.grayColor,
        disabled: _vm.disabled,
      },
      on: { click: _vm.select },
    },
    [
      _vm.icon !== "none"
        ? _c(
            "div",
            { staticClass: "icon-wrapper" },
            [
              _vm.isRectangle
                ? _c("img", {
                    staticClass: "icon-rectangle",
                    attrs: { src: _vm.icon },
                  })
                : _c("image-view-round", {
                    staticClass: "icon",
                    attrs: { src: _vm.icon, placeholder: _vm.iconPlaceholder },
                  }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "font-sm",
          class: {
            label: true,
            ellipsis: true,
            "none-icon": _vm.icon === "none",
            filterMode: _vm.filterMode,
          },
        },
        [_vm._v(" " + _vm._s(_vm.label) + " ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }